/**
 * @author Sergey Tumarkin https://tumarkin.me
 */
import { 
    Alert,
    FloatingLabel,
    CloseButton, 
    Form,
    Button,
    Card,
    Image,
    Container,
    Row,
    Col,
    Tab,
    Tabs,
    Nav
 } from 'react-bootstrap';
import * as API from '../data/API';
import { useState, useEffect, useCallback, useRef } from 'react';
import SortableList, { SortableItem } from "react-easy-sort";
import arrayMove from "array-move";
import * as Config from '../data/Config';


export default function SettingsReport ( props ){
    const {authtoken} = props

    const [isLoading, setIsLoading] = useState(false)
    
    const [account, setAccount] = useState({})
    const [name, setName] = useState('')
    const [location, setLocaton] = useState('')
    const [requisites, setRequisites] = useState('')
    const [reportText, setReportText] = useState('')
    const [logo, setLogo] = useState('')
    const [stamp, setStamp] = useState('')
    const [chief, setСhief] = useState('')
    const [facsimile, setFacsimile] = useState('')
    
    const [isAccountChanged, setIsAccountChanged] = useState(false)
    const [postAccountSuccess, setPostAccountSuccess] = useState(false)

    const [tab, setTab] = useState('main')

    const fileInput = useRef(null)

    const [attachmentfiles, setAttachmentfiles] = useState([])
    

    
    useEffect(() => {
        setIsAccountChanged( 
            name!=account.name 
            || location!=account.location 
            || requisites!=account.requisites 
            || chief!=account.chief  
            || reportText!=account.reportText  
        )
    }, [name, location, requisites, chief, reportText])


    useEffect(() => {
        getAccount()
    }, [])

    const getAccount = () => {
        setIsLoading(true)
        API.Get({ method:'getaccount', authtoken})
        .then(res => {
            const {result, account} = res.data
            if ( result && account ){
                account.reportText = account.report_text // matching between DB and scripts naming
                const {name, location, requisites, reportText, attachmentfiles, logo, stamp, chief, facsimile} = account
                setAccount( account )
                setName( name )
                setLocaton( location )
                setRequisites( requisites )
                setReportText( reportText )
                setAttachmentfiles( isJsonString(attachmentfiles) ? JSON.parse(attachmentfiles) : [] )
                setLogo( logo )
                setStamp( stamp )
                setFacsimile( facsimile )
                setСhief( chief )
            }
        })
        .finally(()=>setIsLoading(false))
    }

    const postAccount = () => {
        setIsLoading(true)
        API.Post({ method:'postaccount', authtoken}, {name, location, requisites, chief, report_text: reportText})
        .then(res => {
            console.log( res.data )
            if (res.data.result){
                setPostAccountSuccess( true )
                setIsAccountChanged( false )
            }
        })
        .finally(()=>setIsLoading(false))
    }

    const postAttachmentFiles = (attachmentfiles) => {
        setIsLoading(true)
        API.Post({ method:'postattachmentfiles', authtoken}, {attachmentfiles: JSON.stringify(attachmentfiles) })
        .then(res => {
            console.log( res.data )
            if (res.data.result){
                // 
            }
        })
        .finally(()=>setIsLoading(false))
    }

    const uploadAccountFile = ( file, type, onSuccess ) => {
        setIsLoading(true)
        const formData = new FormData();
        formData.append('type', type)
        formData.append('file', file)
        formData.append('filename', Date.now()+'.'+file.name.match(/\.([^.]+)$|$/)[1]) // change any file name to timestamp.png|jpg
        const config = { header: { 'content-type': 'multipart/form-data' }};
        API.Post({ method:'uploadaccountfile', authtoken}, formData)
        .then(res => {
            console.log( res.data )
            const {result, files} = res.data
            if (files && onSuccess){
                onSuccess( isJsonString(files) ? JSON.parse(files) : files )
            }
        })
        .finally(()=>setIsLoading(false))
    }

    const onSortEnd = (oldIndex, newIndex) => {
        setAttachmentfiles((array) => {
            const sortedArray = arrayMove(array, oldIndex, newIndex)
            postAttachmentFiles( sortedArray )
            return sortedArray
        });
    };

    const isJsonString = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    const removeAttachmentFile = ( fileToRemove ) => {
        console.log(fileToRemove)
        setIsLoading(true)
        const attachmentfilesWithoutRemovedFile = attachmentfiles.filter( file => (file!=fileToRemove ))
        API.Post({ method:'removeattachmentfile', authtoken}, { attachmentfiles: JSON.stringify(attachmentfilesWithoutRemovedFile), filetoremove: fileToRemove })
        .then(res => {
            console.log( res.data )
            if (res.data.result){
                setAttachmentfiles(  attachmentfilesWithoutRemovedFile )
                // 
            } else {
                alert("Ошибка. Не удалось удалить файл")
            }
        })
        .finally(()=>setIsLoading(false))
    }

    function isHTMLString(str) {
        const regex = /<([a-z][\s\S]*?)>/i;
        return regex.test(str);
    }

    const FileUploaderUI = ( getter, setter, type, name) => {
        const fileInput = useRef(null)

        return (
            <div className="col col-12 col-md-6">
                <Alert 
                    variant="light" 
                    // style={{maxWidth:240}} 
                    className='me-3 mb-4'
                >
                    <div className='text-center'>
                        { getter ? (
                            <Image src={Config.Domain+getter} rounded style={{maxWidth: 200, maxHeight: 200, height: 200}} className="mb-3 object-fit-contain"/>
                        ) : (
                            <div className='p-4' style={{height: 200}}>
                                <p className='h5'>{name}</p>
                                <p className='h5'>{getter}</p>
                                <p className='small'>Пока не загружен</p>
                            </div>
                        )}
                    </div>
                    <Form.Control 
                        className="d-none"
                        type="file" 
                        ref={fileInput}
                        accept="image/png, image/jpeg, image/jpg"
                        onChange={(e)=> {
                            uploadAccountFile( e.target.files[0], type, setter)
                        }}
                    />

                    <Button
                        variant="outline-dark"
                        disabled={isLoading}
                        onClick={ () => fileInput.current.click() }
                        className="w-100"
                    >
                        <div>{ getter ? "Заменить" : "Загрузить"} {name.toLocaleLowerCase()}</div>
                        <div className='small'><div className='small'>.jpg .png .webp</div></div>
                    </Button>
                </Alert>
            </div>
        )
    }
    
    return (
        <>
            <h1 >Настройки отчета</h1>

            <Alert variant="info" className='mb-3 d-block d-md-none'>
                C настройками удобнее работать на большом экране компьютера в панели управления по адресу <a href="#">my.priemka-pro.ru</a>
            </Alert>

            <Tab.Container id="left-tabs-example" defaultActiveKey="main">
                <Row>
                    <Col sm={3} className='mb-5'>
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link eventKey="main">Общие</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="logo">Лого и печать</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="attachments">Сканы документов</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col sm={9}>
                        <Tab.Content>
                            <Tab.Pane eventKey="main">
                                <Container className='ms-0 ps-0'>
                                    <Row>
                                        <Col>
                                            <FloatingLabel label="Наименование организации / ФИО специалиста" className='mb-3'>
                                                <Form.Control 
                                                    id="name"
                                                    type="text" 
                                                    value={name} 
                                                    readOnly={true}
                                                />
                                            </FloatingLabel>

                                            <FloatingLabel label="Местоположение" className='mb-3'>
                                                <Form.Control
                                                    id="location" 
                                                    type="text" 
                                                    value={location} 
                                                    onChange={(e)=> setLocaton(e.target.value) }
                                                />
                                            </FloatingLabel>

                                            <FloatingLabel label="Реквизиты организации/специалиста" className='mb-4'>
                                                <Form.Control 
                                                    id="requisites" 
                                                    as="textarea"
                                                    rows={4}
                                                    value={requisites} 
                                                    onChange={(e)=> setRequisites(e.target.value) }
                                                    style={{height: 200}}
                                                    readOnly={isHTMLString(requisites)}
                                                />
                                                <Form.Text muted className='d-flex ms-3'>
                                                        Отображается в верхнем колонтитуле Акта осмотра и в Заключении, содержит все реквизиты вашей организации (наименование, конткентыне данные, ОГРН, ИНН, КПП). Используйте переносы строк.
                                                </Form.Text>
                                            </FloatingLabel>
                                            
                                            <FloatingLabel label="Текст заключения" className='mb-4'>
                                                <Form.Control 
                                                    id="reportText" 
                                                    as="textarea"
                                                    rows={8}
                                                    value={reportText} 
                                                    onChange={(e)=> setReportText(e.target.value) }
                                                    style={{height: 240}}
                                                />
                                                <Form.Text muted className='d-flex ms-3'>
                                                        Текст свободного содержания, отображается только в Заключении.
                                                </Form.Text>
                                            </FloatingLabel>

                                            {/* <!-- realease after 30.01.2025 --> */}
                                            {
                                                Config.FeatureToggles.chief &&
                                                <FloatingLabel label="ФИО руководителя организации" className='mb-3'>
                                                    <Form.Control
                                                        id="location" 
                                                        type="text" 
                                                        value={chief} 
                                                        onChange={(e)=> setСhief(e.target.value) }
                                                    />
                                                </FloatingLabel>
                                            }

                                            {
                                                postAccountSuccess && !isAccountChanged ? (
                                                    <Alert className="mt-3" key={"postAccountSuccess"} variant={"success"}>
                                                        Сохранено успешно
                                                    </Alert>
                                                ) : null
                                            }

                                            <div className="row py-3">
                                                        <div className="col col-12 col-md-6">
                                                            <Button
                                                                variant="primary"
                                                                className='w-100 mb-3 px-5 py-3'
                                                                disabled={!isAccountChanged}
                                                                onClick={ postAccount }
                                                            >
                                                                Сохранить изменения
                                                            </Button>
                                                        </div>
                                                
                                            </div>
                                            
                                        </Col>
                                    </Row>
                                </Container>
                            </Tab.Pane>

                            <Tab.Pane eventKey="logo">
                                <Container className='ms-0 ps-0'>
                                    <Row>
                                        { FileUploaderUI( logo, setLogo, 'logo', 'Логотип') }
                                        { FileUploaderUI( stamp, setStamp, 'stamp', 'Печать') }
                                        { Config.FeatureToggles.chief && FileUploaderUI( facsimile, setFacsimile, 'facsimile', 'Факсимиле руководителя') }
                                    </Row>
                                </Container>
                                <Alert variant="info" className='mb-3'>
                                    Факсимиле (подпись) для отчетов можно загрузить в настройках пользователя
                                </Alert>
                            </Tab.Pane>
                            
                            <Tab.Pane eventKey="attachments">
                                <Card className="text-center">
                                    <Card.Body>
                                        <Card.Text className='m-4 small'>
                                            Загрузите здесь сканы документов организации (сертификаты, допуски и т.п).<br/>Эти документы будут приложены ко всем отчетам.
                                        </Card.Text>
                                        
                                        <Form.Control 
                                            className="d-none"
                                            type="file" 
                                            ref={fileInput}
                                            accept="image/png, image/jpeg, image/jpg"
                                            onChange={(e)=> {
                                                uploadAccountFile(e.target.files[0], 'attachment', setAttachmentfiles)
                                            }}
                                        />

                                        <Button
                                            className="mb-3"
                                            variant="primary"
                                            onClick={ () => fileInput.current.click() }
                                            disabled={isLoading}
                                        >
                                            Загрузить скан (.jpg, .png)
                                        </Button>
                                    </Card.Body>
                                    <Card.Footer className="text-muted p-3">
                                        {
                                            attachmentfiles.length>0 ? (
                                                <SortableList onSortEnd={onSortEnd} className="list" draggedItemClassName="dragged">
                                                    {attachmentfiles.map((file) => (
                                                        <SortableItem key={file} style={{display: 'grid'}}>
                                                            <div className="d-inline-flex" style={{cursor: 'grab'}}>
                                                                <Image src={Config.Domain+file} rounded style={{maxHeight: 200}} className="mb-3"/>
                                                                <CloseButton className="me-3 align-top" onClick={()=>removeAttachmentFile(file)}/>
                                                            </div>
                                                            
                                                        </SortableItem>
                                                    ))}
                                                </SortableList>
                                            ) : 'Ни одно изображение еще не загружено'
                                        }
                                    </Card.Footer>
                                </Card>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </>
    )
}
